import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
/**
 * Detects if the device is a mobile device.
 *
 * @author Yury Kuntsou
 * @function useMobileDetector
 * @category Hooks
 * @returns {boolean} Whether the device is a mobile device or not.
 */

var useMobileDetector = function useMobileDetector() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isMobile = _useState2[0],
      setMobile = _useState2[1];

  useEffect(function () {
    var hasTouchScreen = false;

    if (typeof navigator.maxTouchPoints !== 'undefined') {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
      var mQ = window.matchMedia && window.matchMedia('(pointer:coarse)');

      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    setMobile(function () {
      return hasTouchScreen;
    });
  }, []);
  return isMobile;
};

var getMobileOperatingSystem = function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || (globalThis === null || globalThis === void 0 ? void 0 : globalThis.opera) || '';
  return {
    android: /android/i.test(userAgent),
    ios: /iPad|iPhone|iPod/.test(userAgent) && !(globalThis !== null && globalThis !== void 0 && globalThis.MSStream)
  };
};

export { getMobileOperatingSystem, useMobileDetector };