import { IconButton } from '@pwa/ui';
import { alpha, Box, styled } from '@mui/material';
import { isDisableOnServer } from '@helpers';
import { Environment } from '@enums';
import { pxToRem } from '@themes';
var isEnableStoryStyles = isDisableOnServer([Environment.Dev, Environment.Test]);
var StyledWrapper = styled(Box)(function (_ref) {
  var $backgroundImg = _ref.$backgroundImg,
      isMobile = _ref.isMobile,
      theme = _ref.theme;
  var styles = {
    alignItems: 'self-start',
    borderRadius: isMobile ? 'unset' : pxToRem(12),
    display: 'flex',
    flexDirection: 'column',
    height: isMobile ? theme.convertToDVH(100) : pxToRem(740),
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: isMobile ? '100%' : pxToRem(350)
  };

  if ($backgroundImg) {
    styles.backgroundImage = "url(".concat($backgroundImg, ")");
    styles.backgroundRepeat = 'no-repeat';

    if (isEnableStoryStyles) {
      styles.backgroundSize = 'cover';
      styles.backgroundPosition = 'center center';
    } else {
      styles.backgroundSize = '100% 100%';
    }
  }

  return styles;
});
var StoryCloseButton = styled(IconButton)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    background: alpha(theme.colors.white, 0.15),
    borderRadius: pxToRem(8),
    height: 24,
    position: 'absolute',
    right: pxToRem(15),
    top: pxToRem(25),
    width: pxToRem(24),
    zIndex: 1000
  };
  return styles;
});
var StyledButtonsContainer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: theme.typography.pxToRem(144),
    padding: theme.spacing(0, 4, 2, 4),
    width: '100%',
    zIndex: 1000
  };
  return styles;
});
var StyledText = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    '& p': {
      margin: 0
    },
    color: theme.colors.white,
    marginTop: theme.spacing(2)
  };
  return styles;
});
var StyledContentWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme,
      $isHasActions = _ref5.$isHasActions;
  var styles = {
    alignItems: 'flex-start',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: $isHasActions ? theme.spacing(5) : theme.spacing(23),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%'
  };
  return styles;
});
var StyledHeader = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    '& p': {
      margin: 0
    },
    color: theme.colors.white
  };
  return styles;
});
export { StoryCloseButton, StyledButtonsContainer, StyledContentWrapper, StyledHeader, StyledText, StyledWrapper };